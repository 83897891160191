<template>
  <v-app>
    <NavDrawer v-if="currentUser !== null && !currentUserWebinar" />
    <MenuBar v-if="currentUser === null && showMenuBar !== false"  />
    <MenuBarUserWebinar v-if="currentUserWebinar && showMenuBar !== false"  />

    <v-main>
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer";
import MenuBar from "./components/MenuBar";
import MenuBarUserWebinar from "./components/MenuBarUserWebinar.vue";
import ERole from "@/models/e-role";

export default {
  components: { MenuBar: MenuBar, NavDrawer, MenuBarUserWebinar },
  name: "App",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentUserWebinar(){
      if(this.$store.state.auth.user){
        return this.$store.state.auth.user.roles.includes(ERole.ROLE_USER);
      } else {
        return false
      }
    },

    showMenuBar() {
      return this.$store.state.potensi.showMenuBar;
    },

  },
  methods: {
    initIcon() {
      let link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      // link.href = this.icon;
      link.href = "/assets/logo.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    isSessionActive() {
      const sessionExpiresAt = localStorage.getItem("sessionExpiresAt");
      if (!sessionExpiresAt) {
        return false; // Sesi tidak ada
      }

      // Periksa apakah waktu kedaluwarsa sesi telah tercapai
      return Date.now() < sessionExpiresAt;
    },

    addVisitor() {
      if (this.isSessionActive()) {
        console.log("Sesion masih aktif (tidak perlu menambahkan visitor)");
      } else {
        localStorage.removeItem("sessionExpiresAt");
        fetch('https://ipapi.co/json/')
            .then(response => response.json())
            .then(data => {

              // console.log(JSON.stringify(data))

              let fVisitor = {
                description: data.city,
                province: data.region,
                country: data.country_name,
                latitude: data.latitude,
                longitude: data.longitude,
                timeZone: data.timezone,
                utcOffset: data.utc_offset,
                ipAddres: data.ip,
              };
              // FVisitorService.addFVisitorCount(fVisitor).then(() => {
              //   // const sessionDuration = 4 * 60 * 60 * 1000; // Durasi sesi dalam milidetik (contoh: 4 x 60  menit)
              //   const sessionDuration = 5 * 60 * 1000; // durasi dalam 5 menit
              //   const sessionExpiresAt = Date.now() + sessionDuration;
              //   localStorage.setItem(
              //     "sessionExpiresAt",
              //     sessionExpiresAt.toString()
              //   );
              // });
              console.log('App.vue', `${fVisitor}`);
            })
            .catch(error =>{
              let fVisitor = {
                description: "Unknown Location",
                province: "Unknown Location",
                country: "Unknown Location",
              };
              // FVisitorService.addFVisitorCount(fVisitor).then(() => {
              //   const sessionDuration = 5 * 60 * 1000; // durasi dalam 5 menit
              //   const sessionExpiresAt = Date.now() + sessionDuration;
              //   localStorage.setItem(
              //       "sessionExpiresAt",
              //       sessionExpiresAt.toString()
              //   );
              //  });
              console.log('App.vue:', `${fVisitor} ${error}`);

            });

      }
    },
  },
  created() {
    this.initIcon();
    this.addVisitor();
  },
  mounted() {
    this.initIcon();

    if (!this.currentUser) {
      //Kita tidak akan pernah push apapun jika belum login
      // this.$router.push('/login');
    }

    /**
     * AKAN DI BERIKAN VALIDATE EXPIRED TOKEN
     */
    // console.log(`Current user is ${JSON.stringify(this.currentUser)}`)
    console.log("###### MOUNTED App.Vue PERTAMA KALI ####");
  },
};
</script>
<style>
.color-gradient-1 {
  background-image: linear-gradient(to top left, #1a7d53 10%, transparent 90%);
}
.bottom-gradient {
  background-image: linear-gradient(to top left, rgb(76, 193, 81) 10%, transparent 90%);
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 0, 0, 0.25),
      rgba(255, 0, 0, 0.25) 5px,
      rgba(0, 0, 255, 0.25) 5px,
      rgba(134, 134, 234, 0.25) 10px
  );
}
.navbar_submenu_color {
  background-image: linear-gradient(to top left, rgba(248, 242, 216, 0.8) 20%, transparent 90%);
}
</style>