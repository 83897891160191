
export const helperModule = {
  namespaced: true,
  state: {
    itemModified: {},
    itemDefault: {},

    sumOfItem: 9999999,

  },
  actions: {
    updateItemModified({ commit }, payload) {
      commit("mutateItemModified", payload);
    },
    updateItemDefault({ commit }, payload) {
      commit("mutateItemDefault", payload);
    },

    setSumOfItem({commit}, payload){
      commit('mutateSumOfItem', payload)
    },
  },
  mutations: {

    updateItemModified({ commit }, payload) {
      commit("mutateItemModified", payload);
    },
    updateItemDefault({ commit }, payload) {
      commit("mutateItemDefault", payload);
    },

    mutateSumOfItem(state, payLoad){
      state.sumOfItem = payLoad
    },

  },

}
