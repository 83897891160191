
export const TargetKegiatanModule = {

  namespaced: true,
  state: {
    itemModified: {},
    itemsFTargetKegiatan: [],
    allItemsFTargetKegiatan: [],


  },

  actions: {
    updateItemModified({commit}, payload){
      commit('mutateItemModified', payload)
    },
    updateItemsFTargetKegiatan({commit}, payload){
      commit('mutateItemsFTargetKegiatan', payload)
    },
    updateAllItemsFTargetKegiatan({commit}, payload){
      commit('mutateAllItemsFTargetKegiatan', payload)
    },
  },
  mutations: {
    mutateItemModified(state, payLoad){
      state.itemModified = payLoad
    },
    mutateItemsFTargetKegiatan(state, payLoad){
      state.itemsFTargetKegiatan = payLoad
    },
    mutateAllItemsFTargetKegiatan(state, payLoad){
      state.allItemsFTargetKegiatan = payLoad
    },
  },


};
