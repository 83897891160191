const ConstApiUrls = {

  // AUTH_SERVICE_URL: "http://localhost:9192/api/auth/",
  // TEST_SERVICE_URL: "http://localhost:9192/api/test/",
  // API_SERVICE_URL: "http://localhost:9192/api/ejakon/",
  //
  // API_SERVICE_URL_CBT: 'http://localhost:9192/api/cbtdes/',
  // API_SERVICE_URL_POINT: "https://point.jatimprov.go.id:8686/api/pirjatim/",

  AUTH_SERVICE_URL: "https://e-jakonku.kalteng.go.id:8080/api/auth/",
  TEST_SERVICE_URL: "https://e-jakonku.kalteng.go.id:8080/api/test/",
  API_SERVICE_URL: "https://e-jakonku.kalteng.go.id:8080/api/ejakon/",
  //
  API_SERVICE_URL_POINT: "https://point.jatimprov.go.id:8686/api/pirjatim/",
  API_SERVICE_URL_CBT: 'https://e-jakonku.kalteng.go.id:8080/api/cbtdes/',

};

export default ConstApiUrls;
