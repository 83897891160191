const En = Object.freeze({
  kantorPusat: "Home Office",
  goodbye: "Good Bye",

  //MENU BAR
  peluangInvestasi: "Investment-Opportunities",
  potensi: "Potentials",
  komoditas: "Commodities",
  profilDaerah: "Regions-Profiles",
  infrastruktur: "Infrastructures",
  kawasan: "Area",
  insentif: "Incentives",
  kawasanIndustri: "Industrial Areas",
  kawasanIndustriHalal: "Halal Industrial Areas",
  kawasanEkonomiKhusus: "Special Economic Areas",
  kawasanPeruntukanIndustri: "Industrial Zoning Areas",

  //PUBLIC DAERAH
  hint: "Title, Description (press ENTER to Search)",
  lihatDetail: "See Details",
  cari: "Search",
  komoditi: "Commodity",
  kabupatenKota: "Regency/City",
  sektor: "Sector",
  filterNamaKecamatan: "District Filter",
  peluang: "Opportunity",

  //PROFILDAERAH DETIL
  peluangPotensi: "Potentials Opportunity",
  kuintalEkor: "Quintals",
  terpetakan: "Mapped",
  kecamatan: "Sub-district",
  kementerianInvestasi: "Ministry of Investment",
  bps: "Central Bureau of Statistics",
  realisasiInvestasi: "Investment Realization",
  bandara: "Airport",
  pelabuhan: "Harbor",
  saranaPendidikan: "Educational Facilities",
  rumahSakit: "Hospital",
  wisata: "Tourist Attractions",
  pasar: "MArket",
  dayaDukung: "Carrying Capacity",
  klikUntukMelihatGrafikDanDetail: "Click to See Chart and Details",
  penduduk: "Population",

  //PUBLIC KAWASAN DETAIL
  peta: "Map",
  informasiUmum: "Common Information",
  informasi: "Information",
  galeri: "Gallery",
  kontak: "Contact",
  unduhanDokumen: "Document Downloaded",
  namaPerusahaan: "Company Name",
  noTelepon: "Call Number",
  asalNegara: "Origin Country",
  tujuan: "Purpose",
  kirim: "Send",
  tahun: "Year",
  umum: "Common",
  areaTotal: "Total Area",
  areaPengembangan: "Development Area",
  areaKosong: "Empty Area",
  skemaPembayaran: "Payment Scheme",
  kepemilikanLahan: "Land Ownership",
  sewa: "Rent",
  biaya: "Cost",
  akuisisiPembelian: "Acquisition Purchase",
  hargaPembelian: "Purchase Price",
  bangunanSiapPakai: "Ready-to-use building ",
  bangunanKantor: "Office Building",
  bangunanKomersial: "Commercial Building",
  jalanUtama: "Main Road (Width)",
  jalanSekunder: "Secondary Road (Width)",
  jalanTersier: "Tertiary Road (Width)",
  utilitas: "Utility",
  listrik: "Electricity",
  gasAlam: "Natural Gas",
  airDomestik: "Domestic Water",
  kapasitas: "Capacity",
  pengolahanAirLimbah: "Waste Water Treatment",
  hari: "Day",
  telekomunikasi: "Telecommunication",
  saluranTelepon: "Telephone Channel",
  saluranKabel: "Cable Channel",
  jenisInternet: "Internet Type",
  providerInternet: " Internet Provider",
  jarakDekatTerhadap: "Close Range Against",
  jarakKotaBesar: "Major City Distance",
  jarakBandara: "Airport Distance",
  jarakPelabuhan: "Harbor Distance",
  jarakAdminPerkantoran: "Office Admin Distance",
  jarakTerminal: "Bus Station Distance",
  jarakHotel: "Hotel Distance",
  jarakPemukiman: "Settlement Distance",
  informasiTenan: "Tenant Information",
  jumlahTenan: "Tenant Total",
  blokTenanTersedia: "Tenant Blocks Available",
  blokTenanSiap: "Tenant Blocks Ready",
  kriteriaKIH: "KIH Criteria",
  jenisPembatasanZonaHalal: "Types of Halal Zone Restrictions",
  logistik: "Logistic",
  laboratoriumDanLPH: "Laboratorium and LPH",
  dataPrimerDanPendukung: "Primary Data And Support",

  //PUBLIC KOMODITI
  //(tidak ada)

  //PUBLIC KPI
  filterKabupatenKota: "Filter Regency/City",

  //PUBLIC POTENSI DETAIL
  nilaiInvestasi: "Investment Value",
  bidangUsaha: "Business Division",

  alamatDPMPTSP:
    "EAST JAVA INVESTMENT AND INTEGRATED LICENSE OFFICE, EAST JAVA PROVINCE",
  adalah: "is a",
  homeText:
    "information center for East Java Province based on GIS Web contains maps of potentials and investment opportunities, complete with local and provincial support capabilities.",
});
export default En;
