import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/public-home/HomeView')
  },
  {
    path: '/admin-berita-agenda',
    name: 'AdminBeritaAgenda',
    component: () =>import('../views/admin-berita-agenda/BeritaAgenda')
  },
  {
    path: "/admin-din-carousel",
    name: "DinCarouselView",
    component: () => import("../views/admin-setup/DinCarouselView.vue"),
  },
  {
    path: "/landing-berita-agenda",
    name: "LandingBeritaAgenda",
    component: () => import("../views/public-berita-agenda/BeritaAgenda.vue"),
  },
  {
    path: "/public-news-page/:id",
    name: "PublicNewsPage",
    component: () => import("../views/public-berita-agenda/PublicNewsPageView.vue"),
  },

  {
    path: '/home',
    name: 'homeView',
    component: () => import('../views/public-home/HomeView')
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/registration/RegistrationView.vue"),
  },
  {
    path: "/registration-masukkan-otp",
    name: "RegistrationMasukkanOtp",
    component: () => import("../views/registration/RegistrationMasukanOtpView.vue"),
  },
  {
    path: "/registation-active",
    name: "RegistrationActive",
    component: () => import("../views/registration/RegistrationActiveView.vue"),
  },

  {
    path: "/reset-atur-ulang",
    name: "ResetAturUlang",
    component: () => import("../views/reset-password/ResetAturUlangView.vue"),
  },
  {
    path: "/reset-masukkan-otp-password",
    name: "ResetMasukanOtpPaswordView",
    component: () => import("../views/reset-password/ResetMasukanOtpPasswordView.vue"),
  },
  {
    path: "/reset-success",
    name: "ResetSuccess",
    component: () => import("../views/reset-password/ResetSuccessView.vue"),
  },

  {
    path: "/reset-metode-verifikasi",
    name: "ResetMetodeVerifikasiView",
    component: () => import("../views/reset-password/ResetMetodeVerifikasiView.vue"),
  },
  // {
  //   path: "/reset-masukkan-otp",
  //   name: "ResetMasukanOtp",
  //   component: () => import("../views/reset-password/ResetMasukanOtpView.vue"),
  // },
  {
    path: "/reset-new-password",
    name: "ResetNewPassword",
    component: () => import("../views/reset-password/ResetNewPasswordView.vue"),
  },

  // {
  //   path: "/verifikasi-login-link/:encryptedTimeOut/:encryptedUserId",
  //   name: "VerifikasiLoginLinkView",
  //   component: () => import("../views/public-verifikasi-login/VerifikasiLoginLinkView.vue"),
  // },
  // {
  //   path: "/verifikasi-login-link-send",
  //   name: "VerifikasiLoginLinkSendView",
  //   component: () => import("../views/public-verifikasi-login/VerifikasiLoginLinkSendView.vue"),
  // },

  {
    path: "/dashboard-utama",
    name: "DashboardUtama",
    component: () => import("../views/Dashboard"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/change-profile",
    name: "ChangeProfile",
    component: () => import("../views/admin/ChangeProfile"),
  },
  {
    path: "/change-profile-user-webinar",
    name: "ChangeProfileUserWebinar",
    component: () => import("../views/public/ChangeProfileUserWebinar.vue"),
  },
  {
    path: "/admin-setting-one",
    name: "AdminSettingOne",
    component: () => import("../views/admin/SettingOneView.vue"),
  },
  {
    path: "/admin-sistem",
    name: "AdminSistem",
    component: () => import("../views/admin/SettingAndUser.vue"),
  },
  {
    path: '/admin-struktur-organisasi',
    name: 'StrukturOrganisasi',
    component: () =>
        import ('../views/admin/StrukturOrganisasi.vue')
  },
  {
    path: '/admin-target-kegiatan',
    name: 'AdminTargetKegiatan',
    component: () =>
        import ('../views/admin-jasa-konstruksi/TargetKegiatanView.vue')
  },
  {
    path: "/admin-tugas-fungsi",
    name: "TugasdanFungsiView",
    component: () =>
        import("../views/admin-jasa-konstruksi/TugasDanFungsiView.vue"),
  },
  {
    path: "/admin-struktur-bidang",
    name: "StrukturBidangView",
    component: () =>
        import("../views/admin-jasa-konstruksi/StrukturBidangView.vue"),
  },
  {
    path: "/admin-pegawai",
    name: "AdminPegawaiView",
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../views/admin-jasa-konstruksi/PegawaiView.vue"
            ),
  },
  {
    path: '/admin-kegiatan-bidang',
    name: 'AdminKegiatanBidang',
    component: () =>
        import ('../views/admin-jasa-konstruksi/FtPaketPekerjaanView.vue')
  },

  {
    path: '/admin-sertif-pelatihan',
    name: 'AdminPlanKegiatanView',
    component: () =>
        import ('../views/admin-kegiatan/PlanKegiatanView.vue')
  },
  {
    path: '/admin-pengawasan-tertib-usaha',
    name: 'FtMonCorpViewTertibUsaha',
    component: () =>
        import ('../views/admin-kegiatan/FtMonCorpView.vue')
  },
  {
    path: '/admin-pengawasan-tertib-jasa',
    name: 'FtMonJasaView',
    component: () =>
        import ('../views/admin-kegiatan/FtMonJasaView.vue')
  },
  {
    path: '/admin-pengawasan-tertib-produk',
    name: 'FtMonProdukView',
    component: () =>
        import ('../views/admin-kegiatan/FtMonProdukView.vue')
  },


  {
    path: '/admin-wilayah',
    name: 'RegionView',
    component: () => import('../views/wilayah/RegionView')
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  //SETUP
  {
    path: "/setup-negara",
    name: "AdminNegaraView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin-setup/CountryView.vue"
      ),
  },
  //EVENT MEETING
  {
    path: "/admin-event",
    name: "AdminEventView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin-event-meeting/EventView.vue"
      ),
  },
  {
    path: "/admin-meeting",
    name: "AdminMeetingView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin-event-meeting/MeetingView.vue"
      ),
  },
  {
    path: "/admin-payment",
    name: "AdminPaymentView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin-event-meeting/PaymentView.vue"
      ),
  },
  {
    path: "/admin-narsum",
    name: "AdminNarsumView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin-event-meeting/NarsumView.vue"
      ),
  },
  {
    path: "/admin-invitee-candidate",
    name: "InviteeCandidateView",
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../views/admin-event-meeting/InviteeCandidateView.vue"
            ),
  },
  {
    path: "/admin-jenis-doc",
    name: "JenisDocView",
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../views/admin-event-meeting/JenisDocView.vue"
            ),
  },
  {
    path: "/admin-article-categ",
    name: "ArticleCategView",
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../views/admin-e-library/ArticleCategView.vue"
            ),
  },
  {
    path: "/admin-article",
    name: "ArticleView",
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../views/admin-e-library/ArticleView.vue"
            ),
  },
  {
    path: "/admin-gallery",
    name: "GalleryView",
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../views/admin-gallery/GalleryView.vue"
            ),
  },

  {
    path: "/admin-reg-webinar",
    name: "AdminRegWebinarView",
    component: () =>
        import("../views/admin-event-meeting/RegWebinarView.vue"),
  },


  //PUBLIC
  {
    path: "/register-webinar/:id",
    name: "RegisterWebinar",
    component: () => import("../views/register-webinar/RegisterWebinarView.vue"),
  },
  {
    path: "/select-account-type",
    name: "SelectAccountType",
    component: () => import("../views/registration/SelectAccountTypeView.vue"),
  },
  {
    path: '/public-event-webinar-detail/:id',
    name: 'WebinarDetailView',
    component: () =>
        import ('../views/public-event-webinar/MeetingDetailView.vue')
  },
  {
    path: '/public-webinar-upload/:id',
    name: 'MeetingUploadView',
    component: () =>
        import ('../views/public-event-webinar/MeetingUploadView.vue')
  },
  {
    path: '/public-event-detail/:id',
    name: 'EventDetailView',
    component: () =>
        import ('../views/public-event-webinar/EventDetailView.vue')
  },

  {
    path: "/webinar-survey",
    name: "WebinarSurvey",
    component: () => import("../views/admin-event-meeting/WebinarSurveyView.vue"),
  },
  {
    path: "/public-jakon-program-kegiatan",
    name: "ProgramKegiatan",
    component: () => import("../views/public-jasa-konstruksi/ProgramKegiatanView.vue"),
  },
  {
    path: "/public-jakon-paket-pekerjaaan",
    name: "FtPaketPekerjaanView",
    component: () => import("../views/public-jasa-konstruksi/FtPaketPekerjaanView.vue"),
  },
  {
    path: "/public-jakon-tugas-dan-fungsi",
    name: "TugasDanFungsiView",
    component: () => import("../views/public-jasa-konstruksi/TugasDanFungsiView.vue"),
  },
  {
    path: "/public-jakon-struktur-bidang",
    name: "StrukturBidangView",
    component: () => import("../views/public-jasa-konstruksi/StrukturBidangView.vue"),
  },


  {
    path: "/public-pelatihan-dan-sertifikasi",
    name: "PelatihanSertifikasiView",
    component: () => import("../views/public-kegiatan/PlanSertifikasiView.vue"),
  },
  {
    path: "/public-bimtek-sosialisasi-seminar-etc",
    name: "PlanBimtekView",
    component: () => import("../views/public-kegiatan/PlanBimtekView.vue"),
  },

  {
    path: "/public-gallery",
    name: "GaleryView",
    component: () => import("../views/public-gallery/GalleryView.vue"),
  },


  // {
  //   path: '/public-webinar',
  //   name: 'WebinarView',
  //   component: () =>
  //       import ('../views/public-event-webinar/MeetingView.vue')
  // },
  {
    path: '/public-webinar',
    name: 'WebinarView',
    component: () =>
        import ('../views/public-event-webinar/MeetingCardsView.vue')
  },

  {
    path: '/public-event',
    name: 'EventView',
    component: () =>
        import ('../views/public-event-webinar/EventView.vue')
  },
  {
    path: '/public-library',
    name: 'ElibraryView',
    component: () =>
        import ('../views/public-e-library/ElibraryView.vue')
  },
  {
    path: '/public-e-library/produk-hukum',
    name: 'ElibraryProdukHukumView',
    component: () =>
        import ('../views/public-e-library/ElibraryProdukHukumView.vue')
  },
  {
    path: '/public-e-library/buku',
    name: 'ElibraryBukuView',
    component: () =>
        import ('../views/public-e-library/ElibraryBukuView.vue')
  },
  {
    path: '/public-e-library/jurnal',
    name: 'ElibraryJurnalView',
    component: () =>
        import ('../views/public-e-library/ElibraryJurnalView.vue')
  },
  {
    path: '/public-e-library/buletin',
    name: 'ElibraryBuletinView',
    component: () =>
        import ('../views/public-e-library/ElibraryBuletinView.vue')
  },
  {
    path: '/public-e-library/artikel',
    name: 'ElibraryArtikelView',
    component: () =>
        import ('../views/public-e-library/ElibraryArtikelView.vue')
  },


  {
    path: '/public-e-library-detil/:id',
    name: 'ElibraryDetilView',
    component: () =>
        import ('../views/public-e-library/ElibraryDetilView.vue')
  },

  {
    path: '/public-online-course',
    name: 'OnlineCouseView',
    component: () =>
        import ('../views/public-online-course-view/OnlineCourseView.vue')
  },
  {
    path: '/public-data-pegawai',
    name: 'DataPegawaiView',
    component: () =>
        import ('../views/public/PegawaiView.vue')
  },
  {
    path: '/public-gallery-detail/:id',
    name: 'GalleryDetilView',
    component: () =>
        import ('../views/public-gallery/GalleryDetilView.vue')
  },

  {
    path: '/public-pengawasan-tertib-usaha-jakon',
    name: 'FtMonCorpView',
    component: () =>
        import ('../views/public-kegiatan/FtMonCorpView.vue')
  },
  {
    path: '/public-pengawasan-tertib-penyelenggaraan-jasa-jakon',
    name: 'FtMonPaketView',
    component: () =>
        import ('../views/public-kegiatan/FtMonPaketView.vue')
  },
  {
    path: '/public-pengawasan-tertib-pemanfaatan-produk-jakon',
    name: 'FtMonProdukViewPemanfaatan',
    component: () =>
        import ('../views/public-kegiatan/FtMonProdukView.vue')
  },


    //CBT
  {
    path: '/admin-cbt-topik',
    name: 'AdminTopikView',
    component: () =>
        import ('@/views/admin-cbt-modul/FTopikView')
  },
  {
    path: '/admin-cbt-tes-soal-bak',
    name: 'AdminSoalView',
    component: () =>
        import ('@/views/admin-cbt-modul/FSoalView')
  },
  {
    path: '/admin-cbt-daftar-peserta',
    name: 'AdminPesertaView',
    component: () =>
        import ('@/views/admin-cbt-peserta/FPesertaView')
  },
  {
    path: '/admin-cbt-daftar-users-peserta',
    name: 'AdminUserPesertaView',
    component: () =>
        import ('@/views/admin-cbt-peserta/UsersPesertaView')
  },
  {
    path: '/admin-cbt-tes',
    name: 'TesView',
    component: () =>
        import ('@/views/admin-cbt-exam/FtTesView')
  },
  {
    path: '/admin-cbt-hasil-tes-soal-choice',
    name: 'AdminHasilTesView',
    component: () =>
        import ('@/views/admin-cbt-exam/FtPesertaSoalChoiceHasilTesView.vue')
  },

  {
    path: '/public-cbt-sesi-tes-daftar-tes-peserta',
    name: 'DaftarTesPesertaView',
    component: () =>
        import ('@/views/public-cbt-sesi-tes/DaftarTesPesertaView')
  },
  {
    path: '/public-cbt-sesi-tes-token-login',
    name: 'LoginTokenView',
    component: () =>
        import ('@/views/public-cbt-sesi-tes/TokenLoginView.vue')
  },
  {
    path: '/public-cbt-sesi-tes-soal',
    name: 'TesSoalView',
    component: () =>
        import ('@/views/public-cbt-sesi-tes/SesiTesSoalView.vue')
  },
  {
    path: '/hasil-tes-soal',
    name: 'HasilTesView',
    component: () =>
        import ('@/views/public-cbt-sesi-tes/HasilTesView.vue')
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
