
export const FEventModule = {
    namespaced: true,
    state: {
      itemModified: Object,
      itemDefault: Object,
      itemDetilModified: Object,
      itemDetilDefault: Object,
      formMode: String,
      formModeFtEventMeeting: String,
      itemsFDivision: [],
      itemsFMeeting: [],
    },
    actions: {
      updateItemModified({commit}, payload){
        commit('mutateItemModified', payload)
      },
      updateItemDefault({commit}, payload){
        commit('mutateItemDefault', payload)
      },
      updateItemDetilModified({commit}, payload){
        commit('mutateItemDetilModified', payload)
      },
      updateItemDetilDefault({commit}, payload){
        commit('mutateItemDetilDefault', payload)
      },
      updateFormMode({commit}, payload){
        commit('mutateFormMode', payload)
      },
      updateFormModeFtEventMeeting({commit}, payload){
        commit('mutateFormModeFtEventMeeting', payload)
      },
      updateItemsFDivision({commit}, payload){
        commit('mutateItemsFDivision', payload)
      },
      updateItemsFMeeting({commit}, payload){
        commit('mutateItemsFMeeting', payload)
      },
    },
  
    mutations: {
      mutateItemModified(state, payLoad){
        state.itemModified = payLoad
      },
      mutateItemDefault(state, payLoad){
        state.itemDefault = payLoad
      },
      mutateItemDetilModified(state, payLoad){
        state.itemDetilModified = payLoad
      },
      mutateItemDetilDefault(state, payLoad){
        state.itemDetilDefault = payLoad
      },
      mutateFormMode(state, payLoad){
        state.formMode = payLoad
      },
      mutateFormModeFtEventMeeting(state, payLoad){
        state.formModeFtEventMeeting = payLoad
      },
      mutateItemsFDivision(state, payLoad){
        state.itemsFDivision = payLoad
      },
      mutateItemsFMeeting(state, payLoad){
        state.itemsFMeeting = payLoad
      },
    },
  
  }
  