
export const FtGalleryModule = {

  namespaced: true,
  state: {
    itemModified: {},
    itemsFtGallery: [],
    allItemsFtGallery: [],


  },

  actions: {
    updateItemModified({commit}, payload){
      commit('mutateItemModified', payload)
    },
    updateItemsFtGallery({commit}, payload){
      commit('mutateItemsFtGallery', payload)
    },
    updateAllItemsFtGallery({commit}, payload){
      commit('mutateAllItemsFtGallery', payload)
    },
  },
  mutations: {
    mutateItemModified(state, payLoad){
      state.itemModified = payLoad
    },
    mutateItemsFtGallery(state, payLoad){
      state.itemsFtGallery = payLoad
    },
    mutateAllItemsFtGallery(state, payLoad){
      state.allItemsFtGallery = payLoad
    },
  },


};
