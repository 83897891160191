
export const FMeetingModule = {
  namespaced: true,
  state: {
    itemModified: {},
    itemDefault: {},
    itemDetilModified: Object,
    itemDetilDefault: Object,
    itemZonasiModified: Object,
    itemZonasiDefault: Object,
    itemMeetingProjectModified: Object,
    itemMeetingProjectDefault: Object,
    itemMeetingInviteeModified: Object,
    itemMeetingInviteeDefault: Object,
    itemRegistrationNeedModified: Object,
    itemRegistrationNeedDefault: Object,
    formMode: String,
    formModeFtMeetingNarsum: String,
    formModeFtMeetingZonasi: String,
    formModeFtMeetingProject: String,
    formModeFtMeetingInvitee: String,
    formModeFtRegistrationNeed: String,
    formModeFtMeetingFile: String,
    itemsFDivision: [],
    itemsFNarsum: [],
    itemsFInviteeCandidate: [],
    itemsFJenisDoc: [],
    itemsFPotensi: [],
    itemsFSector: [],
    itemsFSectorType: [],
    itemsFArea: [],
    itemsFSubArea: [],
    isAddNewNarsum: false,
    isAddNewInvitee: false,
    isAddNewJenisDoc: false,
    isRefreshInvitee: false
  },
  actions: {
    updateItemModified({ commit }, payload) {
      commit("mutateItemModified", payload);
    },
    updateItemDefault({ commit }, payload) {
      commit("mutateItemDefault", payload);
    },
    updateItemDetilModified({ commit }, payload) {
      commit("mutateItemDetilModified", payload);
    },
    updateItemDetilDefault({ commit }, payload) {
      commit("mutateItemDetilDefault", payload);
    },
    updateItemZonasiModified({ commit }, payload) {
      commit("mutateItemZonasiModified", payload);
    },
    updateItemZonasiDefault({ commit }, payload) {
      commit("mutateItemZonasiDefault", payload);
    },
    updateItemMeetingProjectModified({ commit }, payload) {
      commit("mutateItemMeetingProjectModified", payload);
    },
    updateItemMeetingProjectDefault({ commit }, payload) {
      commit("mutateItemMeetingProjectDefault", payload);
    },
    updateItemMeetingInviteeModified({ commit }, payload) {
      commit("mutateItemMeetingInviteeModified", payload);
    },
    updateItemMeetingInviteeDefault({ commit }, payload) {
      commit("mutateItemMeetingInviteeDefault", payload);
    },
    updateItemRegistrationNeedModified({ commit }, payload) {
      commit("mutateItemRegistrationNeedModified", payload);
    },
    updateItemRegistrationNeedDefault({ commit }, payload) {
      commit("mutateItemRegistrationNeedDefault", payload);
    },
    updateFormMode({ commit }, payload) {
      commit("mutateFormMode", payload);
    },
    updateFormModeFtMeetingNarsum({ commit }, payload) {
      commit("mutateFormModeFtMeetingNarsum", payload);
    },
    updateFormModeFtMeetingZonasi({ commit }, payload) {
      commit("mutateFormModeFtMeetingZonasi", payload);
    },
    updateFormModeFtMeetingProject({ commit }, payload) {
      commit("mutateFormModeFtMeetingProject", payload);
    },
    updateFormModeFtMeetingInvitee({ commit }, payload) {
      commit("mutateFormModeFtMeetingInvitee", payload);
    },
    updateFormModeFtMeetingFile({ commit }, payload) {
      commit("mutateFormModeFtMeetingFile", payload);
    },
    updateFormModeFtRegistrationNeed({ commit }, payload) {
      commit("mutateFormModeFtRegistrationNeed", payload);
    },
    updateItemsFDivision({ commit }, payload) {
      commit("mutateItemsFDivision", payload);
    },
    updateItemsFNarsum({ commit }, payload) {
      commit("mutateItemsFNarsum", payload);
    },
    updateItemsFInviteeCandidate({ commit }, payload) {
      commit("mutateItemsFInviteeCandidate", payload);
    },
    updateItemsFJenisDoc({ commit }, payload) {
      commit("mutateItemsFJenisDoc", payload);
    },
    updateItemsFPotensi({ commit }, payload) {
      commit("mutateItemsFPotensi", payload);
    },
    updateItemsFSector({ commit }, payload) {
      commit("mutateItemsFSector", payload);
    },
    updateItemsFSectorType({ commit }, payload) {
      commit("mutateItemsFSectorType", payload);
    },
    updateItemsFArea({ commit }, payload) {
      commit("mutateItemsFArea", payload);
    },
    updateItemsFSubArea({ commit }, payload) {
      commit("mutateItemsFSubArea", payload);
    },
    updateIsAddNewNarsum({ commit }, payload) {
      commit("mutateIsAddNewNarsum", payload);
    },
    updateIsAddNewInvitee({ commit }, payload) {
      commit("mutateIsAddNewInvitee", payload);
    },
    updateIsAddNewJenisDoc({ commit }, payload) {
      commit("mutateIsAddNewJenisDoc", payload);
    },
    updateIsRefreshInvitee({ commit }, payload) {
      commit("mutateIsRefreshInvitee", payload);
    },
  },

  mutations: {
    mutateItemModified(state, payLoad) {
      state.itemModified = payLoad;
    },
    mutateItemDefault(state, payLoad) {
      state.itemDefault = payLoad;
    },
    mutateItemDetilModified(state, payLoad) {
      state.itemDetilModified = payLoad;
    },
    mutateItemDetilDefault(state, payLoad) {
      state.itemDetilDefault = payLoad;
    },
    mutateItemZonasiModified(state, payLoad) {
      state.itemZonasiModified = payLoad;
    },
    mutateItemZonasiDefault(state, payLoad) {
      state.itemZonasiDefault = payLoad;
    },
    mutateItemMeetingProjectModified(state, payLoad) {
      state.itemMeetingProjectModified = payLoad;
    },
    mutateItemMeetingProjectDefault(state, payLoad) {
      state.itemMeetingProjectDefault = payLoad;
    },
    mutateItemMeetingInviteeModified(state, payLoad) {
      state.itemMeetingInviteeModified = payLoad;
    },
    mutateItemMeetingInviteeDefault(state, payLoad) {
      state.itemMeetingInviteeDefault = payLoad;
    },
    mutateItemRegistrationNeedModified(state, payLoad) {
      state.itemRegistrationNeedModified = payLoad;
    },
    mutateItemRegistrationNeedDefault(state, payLoad) {
      state.itemRegistrationNeedDefault = payLoad;
    },
    mutateFormMode(state, payLoad) {
      state.formMode = payLoad;
    },
    mutateFormModeFtMeetingNarsum(state, payLoad) {
      state.formModeFtMeetingNarsum = payLoad;
    },
    mutateFormModeFtMeetingZonasi(state, payLoad) {
      state.formModeFtMeetingZonasi = payLoad;
    },
    mutateFormModeFtMeetingProject(state, payLoad) {
      state.formModeFtMeetingProject = payLoad;
    },
    mutateFormModeFtMeetingInvitee(state, payLoad) {
      state.formModeFtMeetingInvitee = payLoad;
    },
    mutateFormModeFtRegistrationNeed(state, payLoad) {
      state.formModeFtRegistrationNeed = payLoad;
    },
    mutateFormModeFtMeetingFile(state, payLoad) {
      state.formModeFtMeetingFile = payLoad;
    },
    mutateItemsFDivision(state, payLoad) {
      state.itemsFDivision = payLoad;
    },
    mutateItemsFNarsum(state, payLoad) {
      state.itemsFNarsum = payLoad;
    },
    mutateItemsFInviteeCandidate(state, payLoad) {
      state.itemsFInviteeCandidate = payLoad;
    },
    mutateItemsFJenisDoc(state, payLoad) {
      state.itemsFJenisDoc = payLoad;
    },
    mutateItemsFPotensi(state, payLoad) {
      state.itemsFPotensi = payLoad;
    },
    mutateItemsFSector(state, payLoad) {
      state.itemsFSector = payLoad;
    },
    mutateItemsFSectorType(state, payLoad) {
      state.itemsFSectorType = payLoad;
    },
    mutateItemsFArea(state, payLoad) {
      state.itemsFArea = payLoad;
    },
    mutateItemsFSubArea(state, payLoad) {
      state.itemsFSubArea = payLoad;
    },
    mutateIsAddNewNarsum(state, payLoad) {
      state.isAddNewNarsum = payLoad;
    },
    mutateIsAddNewInvitee(state, payLoad) {
      state.isAddNewInvitee = payLoad;
    },
    mutateIsAddNewJenisDoc(state, payLoad) {
      state.isAddNewJenisDoc = payLoad;
    },
    mutateIsRefreshInvitee(state, payLoad) {
      state.isRefreshInvitee = payLoad;
    },
  },
};
