const currentPesertaInitState = JSON.parse(localStorage.getItem('currentPeserta'))
const currentFtTesInitState = JSON.parse(localStorage.getItem('currentFtTes'))
const currentSoalActiveInitState = JSON.parse(localStorage.getItem('currentSoalActive'))
const currentTokenGrantedInitState = JSON.parse(localStorage.getItem('currentTokenGranted'))
const currentFtTesPesertaInitState = JSON.parse(localStorage.getItem('currentFtTesPeserta'))
// const currentPeserta = user
//     ? { status: { loggedIn: true }, user }
//     : { status: { loggedIn: false }, user: null };

export const SesiTesModule = {
  namespaced: true,
  state: {
    formMode: String,
    formModeFSoalChoice: String,
    itemModified: Object,
    itemDefault: Object,
    itemDetilModified: Object,
    itemDetilDefault: Object,
    itemsFDivision: [],
    currentPeserta: currentPesertaInitState,
    currentFtTes: currentFtTesInitState,
    currentSoalActive: currentSoalActiveInitState,
    currentTokenGranted: currentTokenGrantedInitState,
    currentFtTesPeserta: currentFtTesPesertaInitState,
  },
  actions: {
    updateFormMode({commit}, payload){
      commit('mutateFormMode', payload)
    },
    updateFormModeFSoalChoice({commit}, payload){
      commit('mutateFormModeFSoalChoice', payload)
    },
    updateFtTesActive({commit}, payload){
      commit('mutateFtTesActive', payload)
    },
    updateItemModified({commit}, payload){
      commit('mutateItemModified', payload)
    },
    updateItemDefault({commit}, payload){
      commit('mutateItemDefault', payload)
    },
    updateItemDetilModified({commit}, payload){
      commit('mutateItemDetilModified', payload)
    },
    updateItemDetilDefault({commit}, payload){
      commit('mutateItemDetilDefault', payload)
    },
    updateTokenGranted({commit}, payload){
      commit('mutateTokenGranted', payload)
    },
    updateItemsFDivision({commit}, payload){
      commit('mutateItemsFDivision', payload)
    },
    updateCurrentPeserta({commit}, payload){
      commit('mutateCurrentPeserta', payload)
    },
    updateCurrentFtTes({commit}, payload){
      commit('mutateCurrentFtTes', payload)
    },
    updateCurrentSoalActive({commit}, payload){
      commit('mutateCurrentSoalActive', payload)
    },
    updateCurrentTokenGranted({commit}, payload){
      commit('mutateCurrentTokenGranted', payload)
    },
    updateCurrentFtTesPeserta({commit}, payload){
      commit('mutateCurrentFtTesPeserta', payload)
    },

  },

  mutations: {
    mutateFtTesActive(state, payload){
      state.ftTesActive = payload
    },
    mutateFormMode(state, payLoad){
      state.formMode = payLoad
    },
    mutateFormModeFSoalChoice(state, payLoad){
      state.formModeFSoalChoice = payLoad
    },
    mutateItemModified(state, payLoad){
      state.itemModified = payLoad
    },
    mutateItemDefault(state, payLoad){
      state.itemDefault = payLoad
    },
    mutateItemDetilModified(state, payLoad){
      state.itemDetilModified = payLoad
    },
    mutateItemDetilDefault(state, payLoad){
      state.itemDetilDefault = payLoad
    },
    mutateTokenGranted(state, payLoad){
      state.tokenGranted = payLoad
    },
    mutateItemsFDivision(state, payLoad){
      state.itemsFDivision = payLoad
    },
    mutateCurrentPeserta(state, payLoad){
      localStorage.setItem("currentPeserta", JSON.stringify(payLoad))
      state.currentPeserta = payLoad
    },
    mutateCurrentFtTes(state, payLoad){
      localStorage.setItem("currentFtTes", JSON.stringify(payLoad))
      state.currentFtTes = payLoad
    },
    mutateCurrentSoalActive(state, payLoad){
      localStorage.setItem("currentSoalActive", JSON.stringify(payLoad))
      state.currentSoalActive = payLoad
    },
    mutateCurrentTokenGranted(state, payLoad){
      localStorage.setItem("currentTokenGranted", JSON.stringify(payLoad))
      state.currentTokenGranted = payLoad
    },
    mutateCurrentFtTesPeserta(state, payLoad){
      localStorage.setItem("currentFtTesPeserta", JSON.stringify(payLoad))
      state.currentFtTesPeserta = payLoad
    },

  },

}
