const ERole = Object.freeze({
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_ADMIN_1: "ROLE_ADMIN_1",
    ROLE_USER: "ROLE_USER",
    ROLE_NARSUM: "ROLE_NARSUM",
    ROLE_PROJECT_OWNER: "ROLE_PROJECT_OWNER",
    ROLE_USER_1: "ROLE_USER_1",

})
const ERoles= Object.freeze([
    ERole.ROLE_ADMIN,
    ERole.ROLE_ADMIN_1,
    ERole.ROLE_USER,
    ERole.ROLE_NARSUM,
    ERole.ROLE_PROJECT_OWNER,
    ERole.ROLE_USER_1
    ])


export { ERole as default, ERoles }