export const FtRegistrationModule = {
  namespaced: true,
  state: {
    itemModified: {},
    itemDefault: {},
    itemDetilModified: {},
    itemDetilDefault: {},
    formMode: String,
    formModeFtRegistration: String,
    itemsFDivision: [],
    itemsFCountry: [],
    itemsFPotensi: [],
    itemsFSector: [],
    itemsFSectorType: [],
    itemsFArea: [],
    itemsFSubArea: [],
    isAddNewNarsum: false,
    itemsFtRegistrationNeed: [],
    itemsFJenisDoc: [],
    itemsFMeeting: [],
  },
  actions: {
    updateItemModified({ commit }, payload) {
      commit("mutateItemModified", payload);
    },
    updateItemDefault({ commit }, payload) {
      commit("mutateItemDefault", payload);
    },
    updateItemDetilModified({ commit }, payload) {
      commit("mutateItemDetilModified", payload);
    },
    updateItemDetilDefault({ commit }, payload) {
      commit("mutateItemDetilDefault", payload);
    },
    
    updateFormMode({ commit }, payload) {
      commit("mutateFormMode", payload);
    },
    updateFormModeFtRegistration({ commit }, payload) {
      commit("mutateFormModeFtRegistration", payload);
    },
    updateItemsFDivision({ commit }, payload) {
      commit("mutateItemsFDivision", payload);
    },
    updateItemsFCountry({ commit }, payload) {
      commit("mutateItemsFCountry", payload);
    },
    updateItemsFPotensi({ commit }, payload) {
      commit("mutateItemsFPotensi", payload);
    },
    updateItemsFSector({ commit }, payload) {
      commit("mutateItemsFSector", payload);
    },
    updateItemsFSectorType({ commit }, payload) {
      commit("mutateItemsFSectorType", payload);
    },
    updateItemsFArea({ commit }, payload) {
      commit("mutateItemsFArea", payload);
    },
    updateItemsFSubArea({ commit }, payload) {
      commit("mutateItemsFSubArea", payload);
    },
    updateIsAddNewNarsum({ commit }, payload) {
      commit("mutateIsAddNewNarsum", payload);
    },
    updateItemsFtRegistrationNeed({ commit }, payload) {
      commit("mutateItemsFtRegistrationNeed", payload);
    },
    updateItemsFJenisDoc({ commit }, payload) {
      commit("mutateItemsFJenisDoc", payload);
    },
    updateItemsFMeeting({ commit }, payload) {
      commit("mutateItemsFMeeting", payload);
    },
  },

  mutations: {
    mutateItemModified(state, payLoad) {
      state.itemModified = payLoad;
    },
    mutateItemDefault(state, payLoad) {
      state.itemDefault = payLoad;
    },
    mutateItemDetilModified(state, payLoad) {
      state.itemDetilModified = payLoad;
    },
    mutateItemDetilDefault(state, payLoad) {
      state.itemDetilDefault = payLoad;
    },
    mutateFormMode(state, payLoad) {
      state.formMode = payLoad;
    },
    mutateFormModeFtRegistration(state, payLoad) {
      state.formModeFtRegistration = payLoad;
    },
    mutateItemsFDivision(state, payLoad) {
      state.itemsFDivision = payLoad;
    },
    mutateItemsFCountry(state, payLoad) {
      state.itemsFCountry = payLoad;
    },
    mutateItemsFPotensi(state, payLoad) {
      state.itemsFPotensi = payLoad;
    },
    mutateItemsFSector(state, payLoad) {
      state.itemsFSector = payLoad;
    },
    mutateItemsFSectorType(state, payLoad) {
      state.itemsFSectorType = payLoad;
    },
    mutateItemsFArea(state, payLoad) {
      state.itemsFArea = payLoad;
    },
    mutateItemsFSubArea(state, payLoad) {
      state.itemsFSubArea = payLoad;
    },
    mutateIsAddNewNarsum(state, payLoad) {
      state.isAddNewNarsum = payLoad;
    },
    mutateItemsFtRegistrationNeed(state, payLoad) {
      state.itemsFtRegistrationNeed = payLoad;
    },
    mutateItemsFJenisDoc(state, payLoad) {
      state.itemsFJenisDoc = payLoad;
    },
    mutateItemsFMeeting(state, payLoad) {
      state.itemsFMeeting = payLoad;
    },
  },
};
