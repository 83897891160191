<template>
  <div v-if="showNav">
    <div class="ma-1" style="z-index:1; position: absolute; right: 0; top: 0" v-if="false">
      <a href="https://point.jatimprov.go.id/">
        <v-img
            :lazy-src= "require('../assets/logo_kalteng.png')"
            max-height="100"
            max-width="125"
            :src="require('../assets/logo_kalteng.png')"
        ></v-img>
      </a>
    </div>
    <nav>
      <v-toolbar
          style="background-color: #ffffff"
          class="elevation-2"
          height="80px"
      >
        <v-app-bar-nav-icon
            @click="drawer = !drawer"
            class="grey--text hidden-md-and-up"
        ></v-app-bar-nav-icon>

        <v-flex class="ml-2 d-flex align-center">
          <div class="mt-2 mb-1 mr-2" v-if="false">
            <v-img
                :lazy-src="require('../assets/logo.png')"
                max-height="80"
                max-width="60"
                :src="require('../assets/logo.png')"
            ></v-img>
          </div>
        </v-flex>

        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'beranda'"
              :class="{ 'btn-switch-on': switchOn === 'beranda' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              to="/home"
              v-if="currentUser === null"
          >
            <span>Home</span>
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'webinar'"
              :class="{ 'btn-switch-on': switchOn === 'webinar' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              to="/public-webinar"
              v-if="currentUser === null"
          >
            <span>E-JAKONKU</span>
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-menu
              bottom
              offset-y
              style="min-width: 500px;"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  :dark="isDarkMode"
                  class="font-weight-bold custom-btn"
                  @click="switchOn = 'public-library'"
                  :class="{ 'btn-switch-on': switchOn === 'public-library' }"
                  plain
                  elevation="0"
                  color="gray darken-1"
                  route
                  to="#"
                  v-if="currentUser === null"

              >
                <span>E-LIBRARY</span>
              </v-btn>
            </template>

            <v-row class="white">
              <v-col cols="12" sm="12" md="6" class="text-center">
               <v-img
                   :src="require('@/assets/images/e-library.jpeg')"
                    class="ma-4 rounded-lg"
                   max-width="250px"
               ></v-img>
                <v-btn to="/public-e-library" small depressed class="my-2" color="success">Kunjungi Library</v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-list subheader class="rounded-lg elevation-0 mt-2">
                  <v-list-item
                      v-for="(item, index) in itemsElibrary"
                      :key="index"
                      class="custom-btn small no-gutters elevation-0"
                      dense
                      :to="item.url"
                  >
                    <v-list-item-subtitle class="small d-flex" :to="item.url" >
                      {{ item.title}}
                      <v-spacer></v-spacer>
                      <v-icon small :color="item.iconColor" class="mr-1">{{ item.icon }}</v-icon>
                    </v-list-item-subtitle>

                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

          </v-menu>

        </v-toolbar-items>


        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'public-gallery'"
              :class="{ 'btn-switch-on': switchOn === 'public-gallery' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              to="/public-gallery"
              v-if="currentUser === null"
          >
            <span>Gallery</span>
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items class="hidden-sm-and-down" v-if="true">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'public-online-course'"
              :class="{ 'btn-switch-on': switchOn === 'public-online-course' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              href="/public-cbt-sesi-tes-daftar-tes-peserta"
              v-if="currentUser === null"
          >
            <span>E-Learning</span>
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items class="mx-2"></v-toolbar-items>

      </v-toolbar>

      <v-navigation-drawer app v-model="drawer"
                           class="hidden-md-and-up"
                           v-if="! this.$vuetify.breakpoint.mdAndUp" >

        <v-layout column>
          <div class="d-flex flex-row justify-center align-center mt-6 mb-2">
            <v-img
                max-height="100"
                max-width="80"
                src="../assets/logo.png"
            ></v-img>
          </div>
          <div class="text-center font-weight-light title ml-1">
            <h1 class=" font-weight-black teks-effect-toright">MEETING-WEBINAR</h1>
            <div class="mt-n1 red--text caption font-weight-bold mb-2">Provinsi Kalimantan Tengah</div>
          </div>

        </v-layout>

        <v-divider></v-divider>
        <v-list>
          <v-list-item route to="/home">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>Beranda</v-list-item-subtitle>
          </v-list-item>
          <v-list-item route to="/public-webinar">
            <v-list-item-icon>
              <v-icon>mdi-monitor-account</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>E-JAKONKU</v-list-item-subtitle>
          </v-list-item>

          <v-list-group
              v-for="nav_drawer in nav_drawers"
              :key="nav_drawer.title"
              :prepend-icon="nav_drawer.icon"
              no-action
              small

          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="body-2" v-text="nav_drawer.title">
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-container class="navbar_submenu_color">
              <v-list-item
                  v-for="submenu in nav_drawer.items"
                  :key="submenu.title"
                  link
                  router
                  :to="submenu.url"
                  dense
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-title class="caption" v-text="submenu.title">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item-icon>
                  <v-icon small>{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>

            </v-container>

          </v-list-group>

          <v-list-item route to="/public-gallery">
            <v-list-item-icon>
              <v-icon>mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>Galeri</v-list-item-subtitle>
          </v-list-item>


          <v-list-group
              v-for="nav_drawer in nav_drawers_kegiatan"
              :key="nav_drawer.title"
              :prepend-icon="nav_drawer.icon"
              no-action
              small
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-subtitle class="body-2" v-text="nav_drawer.title">
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>

            <v-container class="navbar_submenu_color">
              <v-list-item
                  v-for="submenu in nav_drawer.items"
                  :key="submenu.title"
                  link
                  router
                  :to="submenu.url"
                  dense
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption" v-text="submenu.title">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <v-list-item-icon>
                  <v-icon small>{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-container>
          </v-list-group>


          <v-divider></v-divider>
          <v-list-item route  href="/public-cbt-sesi-tes-daftar-tes-peserta" v-if="false">
            <v-list-item-icon>
              <v-icon>mdi-play-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle class="font-weight-bold orange--text">E-Learning</v-list-item-subtitle>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item route to="/login">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle class="blue--text">Login</v-list-item-subtitle>
          </v-list-item>

        </v-list>



      </v-navigation-drawer>

    </nav>
  </div>

</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      drawer: false,
      isDarkMode: false,
      switchOn: false,

      itemsElibrary: [
        { title: 'Produk Hukum', url: '/public-e-library/produk-hukum', icon: "mdi-scale-balance" , iconColor:"red" },
        { title: 'Buku', url: '/public-e-library/buku', icon: "mdi-book-open-variant", iconColor:""  },
        { title: 'Jurnal Ilmiah', url: '/public-e-library/jurnal', icon: "mdi-flask-outline", iconColor:"blue"  },
        { title: 'Buletin', url: '/public-e-library/buletin', icon: "mdi-newspaper", iconColor:"green"  },
        { title: 'Artikel', url: '/public-e-library/artikel', icon: "mdi-feather", iconColor:"pink"  },
      ],


    };
  },
  computed: {
    showNav() {
      const excludePaths = [
        "/login", "/register",
        "/verifikasi-login-link",
        "/register-webinar",
        "/registration-masukkan-otp",
        "/registation-active",

        "/reset-atur-ulang",
        "/reset-masukkan-otp",
        "/reset-masukkan-otp-password"

      ];

      const currentPath = this.$route.path;

      return !excludePaths.some((path) => currentPath.startsWith(path));
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    nav_drawers() {
      return [
        {
          title: "E-Library",
          icon: "mdi-book-open-variant",
          items: this.itemsElibrary,
        },
      ];

    },
    nav_drawers_kegiatan() {
      return [

      ];

    },

  },
  methods: {
  },
};
</script>

<style scoped>
.title h1 {
  font-size: 0px;
  font-weight: bolder;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #3f51b5, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 24px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.waviy {
  position: relative;
  font-weight: bolder;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: #0f2270;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.custom-btn {
  color: #000000; /* Warna teks */
  background-color: #ffffff; /* Warna latar belakang */
  border-radius: 1px; /* Sudut tombol */
  letter-spacing: 0px;
}

.custom-btn:hover {
  background-color: rgb(
    235,
    243,
    235
  ); /* Warna latar belakang tipis saat hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Bayangan saat dihover */
}

.btn-switch-on {
  background-color: rgb(
    214,
    235,
    214
  ); /* Warna latar belakang tipis saat hover */
  border-radius: 5px;
}

</style>