export default class FtRegistration {
  constructor(
    id,
    kode1,
    description,
    fdivisionBean,

    userBean,
    fmeetingBean,

    regOn,
    confirmed,
    status,
    alasan,
    lunas,
    approved,

    needsStatus,

    fileSertifikat

  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.fdivisionBean = fdivisionBean;
    this.userBean = userBean;
    this.fmeetingBean = fmeetingBean;
    this.regOn = regOn;
    this.confirmed = confirmed;
    this.status = status;
    this.alasan = alasan;
    this.lunas = lunas;
    this.approved = approved;

    this.needsStatus = needsStatus;

    this.fileSertifikat = fileSertifikat;
  }
}
