const Id = Object.freeze({
  kantorPusat: "Kantor Pusat",
  goodbye: "Sampai jumpa!",

  //MENU BAR
  peluangInvestasi: "Peluang-Investasi",
  potensi: "Potensi",
  komoditas: "Komoditas",
  profilDaerah: "Profil-Daerah",
  infrastruktur: "Infrastruktur",
  kawasan: "Kawasan",
  insentif: "Insentif",
  kawasanIndustri: "Kawasan Industri",
  kawasanIndustriHalal: "Kawasan Industri Halal",
  kawasanEkonomiKhusus: "Kawasan Ekonomi Khusus",
  kawasanPeruntukanIndustri: "Kawasan Peruntukan Industri",

  //PUBLIC DAERAH
  hint: "Judul, Deskripsi Singkat (tekan ENTER untuk mencari)",
  lihatDetail: "Lihat Detail",
  cari: "Cari",
  komoditi: "Komoditi",
  kabupatenKota: "Kabupaten Kota",
  sektor: "Sektor",
  filterNamaKecamatan: "Filter Nama Kecamatan",
  peluang: "Peluang",

  //PROFILDAERAH DETIL
  peluangPotensi: "Peluang Potensi",
  kuintalEkor: "kuintal/ekor",
  terpetakan: "Terpetakan",
  kecamatan: "Kecamatan",
  kementerianInvestasi: "Kementerian Investasi",
  bps: "BPS",
  realisasiInvestasi: "Realisasi Investasi",
  bandara: "Bandara",
  pelabuhan: "Pelabuhan",
  saranaPendidikan: "Sarana Pendidikan",
  rumahSakit: "Rumah Sakit",
  wisata: "Wisata",
  pasar: "Pasar",
  dayaDukung: "Daya Dukung",
  klikUntukMelihatGrafikDanDetail: "Klik untuk melihat Grafik dan Detail",
  penduduk: "Penduduk",

  //PUBLIK DAYA DUKUNG
  koordinat: "Koordinat",
  tutupGaleri: "Tutup Galeri",
  lihatGaleri: "Lihat Galeri",
  urutkan: "Urutkan",
  selengkapnya: "Selengkapnya",

  //PUBLIC INSENTIF

  //PUBLIC INFRASTRUKTUR

  //PUBLIC KAWASAN DETAIL
  peta: "Peta",
  informasiUmum: "Informasi Umum",
  informasi: "Informasi",
  galeri: "Galeri",
  kontak: "Kontak",
  unduhanDokumen: "Unduhan Dokumen",
  namaPerusahaan: "Nama Perusahaan",
  noTelepon: "No. Telepon",
  asalNegara: "Asal Negara",
  tujuan: "Tujuan",
  kirim: "Kirim",
  tahun: "Tahun",
  umum: "Umum",
  areaTotal: "Area Total",
  areaPengembangan: "Area Pengembangan",
  areaKosong: "Area Kosong",
  skemaPembayaran: "Skema Pembayaran",
  kepemilikanLahan: "Kepemilikan Lahan",
  sewa: "Sewa",
  biaya: "Biaya",
  akuisisiPembelian: "Akuisisi Pembelian",
  hargaPembelian: "Harga Pembelian",
  bangunanSiapPakai: "Bangunan Siap Pakai",
  bangunanKantor: "Bangunan Kantor",
  bangunanKomersial: "Bangunan Komersial",
  jalanUtama: "Jalan Utama (Lebar)",
  jalanSekunder: "Jalan Sekunder (Lebar)",
  jalanTersier: "Jalan Tersier (Lebar)",
  utilitas: "Utilitas",
  listrik: "Listrik",
  gasAlam: "Gas Alam",
  airDomestik: "Air Domestik",
  kapasitas: "Kapasitas",
  pengolahanAirLimbah: "Pengolahan Air Limbah",
  hari: "Hari",
  telekomunikasi: "Telekomunikasi",
  saluranTelepon: "Saluran Telepon",
  saluranKabel: "Saluran Kabel",
  jenisInternet: "Jenis Internet",
  providerInternet: "Provider Internet",
  jarakDekatTerhadap: "Jarak Dekat Terhadap",
  jarakKotaBesar: "Jarak Kota Besar",
  jarakBandara: "Jarak Bandara",
  jarakPelabuhan: "Jarak Pelabuhan",
  jarakAdminPerkantoran: "Jarak Admin Perkantoran",
  jarakTerminal: "Jarak Terminal",
  jarakHotel: "Jarak Hotel",
  jarakPemukiman: "Jarak Pemukiman",
  informasiTenan: "Informasi Tenan",
  jumlahTenan: "Jumlah Tenan",
  blokTenanTersedia: "Blok Tenan Tersedia",
  blokTenanSiap: "Blok Tenan Siap",
  kriteriaKIH: "kriteria KIH",
  jenisPembatasanZonaHalal: "Jenis Pembatalan Zona Halal",
  logistik: "Logistik",
  laboratoriumDanLPH: "Laboratorium dan LPH",
  dataPrimerDanPendukung: "Data Primer Dan Pendukung",

  //PUBLIC KOMODITI
  //(tidak ada)

  //PUBLIC KPI
  filterKabupatenKota: "Filter Kabupaten/Kota",

  //PUBLIC POTENSI DETAIL
  nilaiInvestasi: "Nilai Investasi",
  bidangUsaha: "Bidang Usaha",

  alamatDPMPTSP: "DPMPTSP PROPINSI JAWA TIMUR",
  adalah: "adalah",
  homeText:
    "Pusat informasi Provinsi Jawa Timur berbasis Webgis yang berisi peta point dan peluang investasi, lengkap dengan daya dukung Daerah maupun daya dukung Provinsi",
});
export default Id;
