import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth-module";
import { registration } from './registration-module'
import { potensi } from "./potensi-module";
import { FEventModule } from "./f-event-module";
import { FMeetingModule } from "./f-meeting-module";
import { FtRegistrationModule } from "./ft-registration-module";
import { TargetKegiatanModule } from "./target-kegiatan-module";
import { FtGalleryModule } from "./ft-gallery-module";
import { langModule } from "@/store/lang-module";
import { helperModule } from "@/store/helper-module";
import { UploadWebinarModule } from "@/store/upload-webinar-module";

import {FSoalModule} from "@/store/cbt/f-soal-module";
import  { SesiTesModule} from "@/store/cbt/sesi-tes-module";
import  { HasilTesModule} from "@/store/cbt/hasil-tes-module";
import {FtTesModule} from "@/store/cbt/ft-tes-module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    potensi,
    registration,
    langModule,
    FEventModule,
    FMeetingModule,
    FtRegistrationModule,
    TargetKegiatanModule,
    FtGalleryModule,
    helperModule,
    UploadWebinarModule,

    FSoalModule,
    FtTesModule,
    SesiTesModule,
    HasilTesModule

  },
});
