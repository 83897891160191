const initLang = localStorage.getItem('lang') || "id";
export const langModule = {
  namespaced: true,
  state: {
    lang: initLang,

  },
  actions: {
    setLang({commit}, payload){
      localStorage.setItem("lang", payload);
      commit('mutateLang', payload)
    },

  },
  mutations: {
    mutateLang(state, payLoad){
      state.lang = payLoad
    },

  },

};
