
export const FSoalModule = {
  namespaced: true,
  state: {
    itemModified: Object,
    itemDefault: Object,
    itemDetilModified: Object,
    itemDetilDefault: Object,
    formMode: String,
    formModeFSoalChoice: String,
    itemsFDivision: [],
    itemsFTopik: []
  },
  actions: {
    updateItemModified({commit}, payload){
      commit('mutateItemModified', payload)
    },
    updateItemDefault({commit}, payload){
      commit('mutateItemDefault', payload)
    },
    updateItemDetilModified({commit}, payload){
      commit('mutateItemDetilModified', payload)
    },
    updateItemDetilDefault({commit}, payload){
      commit('mutateItemDetilDefault', payload)
    },
    updateFormMode({commit}, payload){
      commit('mutateFormMode', payload)
    },
    updateFormModeFSoalChoice({commit}, payload){
      commit('mutateFormModeFSoalChoice', payload)
    },
    updateItemsFDivision({commit}, payload){
      commit('mutateItemsFDivision', payload)
    },
    updateItemsFTopik({commit}, payload){
      commit('mutateItemsFTopik', payload)
    },
  },

  mutations: {
    mutateItemModified(state, payLoad){
      state.itemModified = payLoad
    },
    mutateItemDefault(state, payLoad){
      state.itemDefault = payLoad
    },
    mutateItemDetilModified(state, payLoad){
      state.itemDetilModified = payLoad
    },
    mutateItemDetilDefault(state, payLoad){
      state.itemDetilDefault = payLoad
    },
    mutateFormMode(state, payLoad){
      state.formMode = payLoad
    },
    mutateFormModeFSoalChoice(state, payLoad){
      state.formModeFSoalChoice = payLoad
    },
    mutateItemsFDivision(state, payLoad){
      state.itemsFDivision = payLoad
    },
    mutateItemsFTopik(state, payLoad){
      state.itemsFTopik = payLoad
    },
  },

}
