
export const HasilTesModule = {
    namespaced: true,
    state: {
      itemsFDivision: [],
      itemsFtTes: []
    },
    actions: {
      updateItemsFDivision({commit}, payload){
        commit('mutateItemsFDivision', payload)
      },
      updateItemsFtTes({commit}, payload){
        commit('mutateItemsFtTes', payload)
      },
    },
  
    mutations: {
      mutateItemsFDivision(state, payLoad){
        state.itemsFDivision = payLoad
      },
      mutateItemsFtTes(state, payLoad){
        state.itemsFtTes = payLoad
      },
      
    },
  
  }
  