
export const FtTesModule = {
  namespaced: true,
  state: {
    itemModified: Object,
    itemDefault: Object,
    formMode: String,
    ftTeses: [],
    itemsFDivision: [],

    ftTesSoals: [],
    allFtTesSoals: [],
    ftTesPesertas: [],
    allFtTesPeserta: [],
    ftTesTopiks: [],

    itemsFSoal: [],
    itemsFTopik: [],
  },
  actions: {
    updateItemModified({commit}, payload){
      commit('mutateItemModified', payload)
    },
    updateItemDefault({commit}, payload){
      commit('mutateItemDefault', payload)
    },
    updateFormMode({commit}, payload){
      commit('mutateFormMode', payload)
    },
    updateFtTeses({commit}, payload){
      commit('mutateFtTeses', payload)
    },
    updateItemsFDivision({commit}, payload){
      commit('mutateItemsFDivision', payload)
    },


    updateFtTesSoals({commit}, payload){
      commit('mutateFtTesSoals', payload)
    },
    updateAllFtTesSoals({commit}, payload){
      commit('mutateAllFtTesSoals', payload)
    },
    updateFtTesPesertas({commit}, payload){
      commit('mutateFtTesPesertas', payload)
    },
    updateAllFtTesPeserta({commit}, payload){
      commit('mutateAllFtTesPeserta', payload)
    },
    updateFtTesTopiks({commit}, payload){
      commit('mutateFtTesTopiks', payload)
    },

    updateItemsFSoal({commit}, payload){
      commit('mutateItemsFSoal', payload)
    },
    updateItemsFTopik({commit}, payload){
      commit('mutateItemsFTopik', payload)
    }


  },

  mutations: {
    mutateItemModified(state, payLoad){
      state.itemModified = payLoad
    },
    mutateItemDefault(state, payLoad){
      state.itemDefault = payLoad
    },
    mutateFormMode(state, payLoad){
      state.formMode = payLoad
    },
    mutateFtTeses(state, payLoad){
      state.ftTeses = payLoad
    },
    mutateItemsFDivision(state, payLoad){
      state.itemsFDivision = payLoad
    },

    mutateFtTesSoals(state, payLoad){
      state.ftTesSoals = payLoad
    },
    mutateAllFtTesSoals(state, payLoad){
      state.allFtTesSoals = payLoad
    },
    mutateFtTesPesertas(state, payLoad){
      state.ftTesPesertas = payLoad
    },
    mutateAllFtTesPeserta(state, payLoad){
      state.allFtTesPeserta = payLoad
    },
    mutateFtTesTopiks(state, payLoad){
      state.ftTesTopiks = payLoad
    },

    mutateItemsFSoal(state, payLoad){
      state.itemsFSoal = payLoad
    },
    mutateItemsFTopik(state, payLoad){
      state.itemsFTopik = payLoad
    }

  },

}
