<template>
  <div v-if="showNav">
    <nav >
      <v-toolbar
          style="background-color: #ffffff"
          class="elevation-2"
          height="80px"
      >
        <v-app-bar-nav-icon
            @click="drawer = !drawer"
            class="grey--text hidden-md-and-up"
        ></v-app-bar-nav-icon>

        <v-flex class="ml-2 d-flex align-center">
          <div class="mt-2 mb-1 mr-2" v-if="false">
            <v-img
                :lazy-src="require('../assets/logo.png')"
                max-height="80"
                max-width="60"
                :src="require('../assets/logo.png')"
            ></v-img>
          </div>
          <div class="font-weight-light title ml-1">
            <h1 class="font-weight-bold">EJAKONKU</h1>
            <div class="subtitle-2 mt-n3 grey--text text--darken-2 font-weight-regular hidden-md-and-down">
              Elektronik Jasa Konstruksi
            </div>
            <div class="subtitle-2 mt-n2 orange--text text--lighten-2 font-weight-regular hidden-md-and-down">
              Prov Kalimantan Tengah
            </div>
          </div>
        </v-flex>

        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'beranda'"
              :class="{ 'btn-switch-on': switchOn === 'beranda' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              to="/home"
              v-if="currentUserWebinar"
          >
            <span>Home</span>
          </v-btn>
        </v-toolbar-items>




        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'webinar'"
              :class="{ 'btn-switch-on': switchOn === 'webinar' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              to="/public-webinar"
              v-if="currentUserWebinar"
          >
            <span>E-JAKONKU</span>
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-menu
              bottom
              offset-y
              style="min-width: 500px;"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  :dark="isDarkMode"
                  class="font-weight-bold custom-btn"
                  @click="switchOn = 'public-library'"
                  :class="{ 'btn-switch-on': switchOn === 'public-library' }"
                  plain
                  elevation="0"
                  color="gray darken-1"
                  route
                  to="#"
                  v-if="currentUserWebinar"
              >
                <span>E-LIBRARY</span>
              </v-btn>
            </template>

            <v-row class="white">
              <v-col cols="12" sm="12" md="6" class="text-center">
                <v-img
                    :src="require('@/assets/images/e-library.jpeg')"
                    class="ma-4 rounded-lg"
                    max-width="250px"
                ></v-img>
                <v-btn to="/public-e-library" small depressed class="my-2" color="success">Kunjungi Library</v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-list subheader class="rounded-lg elevation-0 mt-2">
                  <v-list-item
                      v-for="(item, index) in itemsElibrary"
                      :key="index"
                      class="custom-btn small no-gutters elevation-0"
                      dense
                      :to="item.url"
                  >
                    <v-list-item-subtitle class="small d-flex" :to="item.url" >
                      {{ item.title}}
                      <v-spacer></v-spacer>
                      <v-icon small :color="item.iconColor" class="mr-1">{{ item.icon }}</v-icon>
                    </v-list-item-subtitle>

                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

          </v-menu>

        </v-toolbar-items>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-menu
              bottom
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  :dark="isDarkMode"
                  class="font-weight-bold custom-btn"
                  @click="switchOn = 'public-jasa-konstruksi'"
                  :class="{ 'btn-switch-on': switchOn === 'public-jasa-konstruksi' }"
                  plain
                  elevation="0"
                  color="gray darken-1"
                  route
                  v-if="currentUserWebinar"

              >
                <span>Jasa Konstruksi</span>
              </v-btn>
            </template>


            <v-row class="white">
              <v-col cols="12" sm="12" md="6" class="text-center">
                <v-img
                    :src="require('@/assets/images/jasa-konstruksi.jpeg')"
                    class="ma-4 rounded-lg"
                    max-width="250px"
                    min-height="200px"
                ></v-img>
                <v-btn v-if="false" to="#" x-small plain depressed class="my-2" color="primary">Jasa Konstruksi</v-btn>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-list subheader class="rounded-lg elevation-0 mt-2">
                  <v-list-item
                      v-for="(item, index) in itemsJakon"
                      :key="index"
                      class="custom-btn small no-gutters elevation-0"
                      dense
                      :to="item.url"
                  >
                    <v-list-item-subtitle class="small d-flex" :to="item.url" >
                      {{ item.title}}
                      <v-spacer></v-spacer>
                      <v-icon small :color="item.iconColor" class="mr-1">{{ item.icon }}</v-icon>
                    </v-list-item-subtitle>

                  </v-list-item>
                </v-list>
              </v-col>

            </v-row>

          </v-menu>

        </v-toolbar-items>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'public-gallery'"
              :class="{ 'btn-switch-on': switchOn === 'public-gallery' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              to="/public-gallery"
              v-if="currentUserWebinar"
          >
            <span>Gallery</span>
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-menu
              offset-y
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  :dark="isDarkMode"
                  class="font-weight-bold custom-btn"
                  @click="switchOn = 'public-kegiatan'"
                  :class="{ 'btn-switch-on': switchOn === 'public-kegiatan' }"
                  plain
                  elevation="0"
                  color="gray darken-1"
                  route
                  v-if="currentUserWebinar"

              >
                <span>Kegiatan</span>
              </v-btn>
            </template>


            <v-row class="white">

              <v-col cols="12" sm="12" md="12">
                <v-list subheader class="rounded-lg elevation-0 mt-2">
                  <v-list-item
                      v-for="(item, index) in itemsKegiatan"
                      :key="index"
                      class="custom-btn small no-gutters elevation-0"
                      dense
                      :to="item.url"
                  >
                    <v-list-item-subtitle class="small caption d-flex" :to="item.url" >
                      {{ item.title}}
                      <v-spacer></v-spacer>
                      <v-icon :color="item.iconColor" class="mr-1">{{ item.icon }}</v-icon>
                    </v-list-item-subtitle>

                  </v-list-item>
                </v-list>
              </v-col>

            </v-row>

          </v-menu>

        </v-toolbar-items>


        <v-toolbar-items class="hidden-sm-and-down" v-if="true">
          <v-btn
              small
              :dark="isDarkMode"
              class="font-weight-bold custom-btn"
              @click="switchOn = 'public-online-course'"
              :class="{ 'btn-switch-on': switchOn === 'public-online-course' }"
              plain
              elevation="0"
              color="gray darken-1"
              route
              href="/public-cbt-sesi-tes-daftar-tes-peserta"
              v-if="currentUserWebinar"
          >
            <span>E-Learning</span>
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items class="mx-2"></v-toolbar-items>


        <v-toolbar-items class="hidden-sm-and-down">
          <v-menu offset-y v-if="currentUserWebinar">
            <template v-slot:activator="{ on, attrs }">
              <v-btn plain v-bind="attrs" v-on="on">
                <v-avatar color="grey lighten-1" size="36">
                  <v-img
                      :src="lookupImageUrl(currentUser)"
                  ></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list color="grey lighten-4">
              <v-list-item
                  v-for="link in top_menus"
                  :key="link.title"
                  route
                  :to="link.route"
                  @click.prevent="handleClick(link.action)"
              >
                <v-list-item-icon>
                  <v-icon small :color="link.iconColor">{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ link.title }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <v-toolbar-items class="mx-4"></v-toolbar-items>

      </v-toolbar>

      <v-navigation-drawer app v-model="drawer"
                           class="hidden-md-and-up"
                           v-if="! this.$vuetify.breakpoint.mdAndUp" >

        <v-layout column>
          <div class="d-flex flex-row justify-center align-center mt-6 mb-2">
            <v-img
                max-height="100"
                max-width="80"
                :src="require('../assets/logo.png')"
            ></v-img>
          </div>
          <div class="text-center font-weight-light title ml-1">
            <h1 class=" font-weight-black teks-effect-toright">E-JAKON</h1>
            <div class="mt-n1 red--text caption font-weight-bold mb-2">Provinsi Kalimantan Tengah</div>
          </div>

        </v-layout>

        <v-divider></v-divider>
        <v-list>
          <v-list-item route to="/home">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>Beranda</v-list-item-subtitle>
          </v-list-item>
          <v-list-item route to="/public-webinar">
            <v-list-item-icon>
              <v-icon>mdi-monitor-account</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>E-JAKONKU</v-list-item-subtitle>
          </v-list-item>

          <v-list-group
              v-for="nav_drawer in nav_drawers"
              :key="nav_drawer.title"
              :prepend-icon="nav_drawer.icon"
              no-action
              small

          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="body-2" v-text="nav_drawer.title">
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-container class="navbar_submenu_color">
              <v-list-item
                  v-for="submenu in nav_drawer.items"
                  :key="submenu.title"
                  link
                  router
                  :to="submenu.url"
                  dense
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-title class="caption" v-text="submenu.title">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item-icon>
                  <v-icon small>{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>

            </v-container>

          </v-list-group>

          <v-list-item route to="/public-gallery">
            <v-list-item-icon>
              <v-icon>mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>Galeri</v-list-item-subtitle>
          </v-list-item>


          <v-list-group
              v-for="nav_drawer in nav_drawers_kegiatan"
              :key="nav_drawer.title"
              :prepend-icon="nav_drawer.icon"
              no-action
              small
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-subtitle class="body-2" v-text="nav_drawer.title">
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>

            <v-container class="navbar_submenu_color">
              <v-list-item
                  v-for="submenu in nav_drawer.items"
                  :key="submenu.title"
                  link
                  router
                  :to="submenu.url"
                  dense
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption" v-text="submenu.title">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <v-list-item-icon>
                  <v-icon small>{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-container>
          </v-list-group>


          <v-divider></v-divider>
          <v-list-item route  href="/public-cbt-sesi-tes-daftar-tes-peserta" v-if="true">
            <v-list-item-icon>
              <v-icon>mdi-play-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle class="font-weight-bold orange--text">E-Learning</v-list-item-subtitle>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item route to="/login" v-if="!currentUserWebinar">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle class="blue--text">Login</v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="currentUserWebinar" to="#" @click="logOut">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="body-2"> Keluar </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-navigation-drawer>
      <div class="my-16">
      </div>

    </nav>
  </div>

</template>

<script>
import FileService from "@/services/apiservices/file-service";
import ERole from "@/models/e-role";

export default {
  name: "MenuBarUserWebinar",
  data() {
    return {
      drawer: false,
      isDarkMode: false,
      switchOn: false,
      top_menus: [
        {
          icon: "mdi-pencil",
          title: "Profil Saya",
          route: "/change-profile-user-webinar",
          action: "",
          visible: true,
          iconColor: "orange",
        },
        {
          icon: "mdi-power",
          title: "Keluar",
          route: "#",
          action: "logOut",
          visible: true,
          iconColor: "red",
        },
      ],


      itemsElibrary: [
        { title: 'Produk Hukum', url: '/public-e-library/produk-hukum', icon: "mdi-scale-balance" , iconColor:"red" },
        { title: 'Buku', url: '/public-e-library/buku', icon: "mdi-book-open-variant", iconColor:""  },
        { title: 'Jurnal Ilmiah', url: '/public-e-library/jurnal', icon: "mdi-flask-outline", iconColor:"blue"  },
        { title: 'Buletin', url: '/public-e-library/buletin', icon: "mdi-newspaper", iconColor:"green"  },
        { title: 'Artikel', url: '/public-e-library/artikel', icon: "mdi-feather", iconColor:"pink"  },
      ],

    };
  },
  computed: {
    currentPeserta: {
      get() {
        return this.$store.state.SesiTesModule.currentPeserta;
      },
      set(value) {
        this.$store.dispatch("SesiTesModule/updateCurrentPeserta", value);
      },
    },
    showNav() {
      const excludePaths = [
        "/login", "/register",
        "/verifikasi-login-link",
        "/register-webinar",
        "/registration-masukkan-otp",
        "/registation-active",

        "/reset-atur-ulang",
        "/reset-masukkan-otp",
        "/reset-masukkan-otp-password",
        "/public-cbt-sesi-tes-token-login",
        "/public-cbt-sesi-tes-soal" ,
        "/hasil-tes-soal"
      ];

      const currentPath = this.$route.path;
      return !excludePaths.some((path) => currentPath.startsWith(path));
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentUserWebinar(){
      if(this.$store.state.auth.user){
        return !!this.$store.state.auth.user.roles.includes(ERole.ROLE_USER);
      } else {
        return false
      }
    },

    nav_drawers() {
      return [
        {
          title: "E-Library",
          icon: "mdi-book-open-variant",
          items: this.itemsElibrary,
        },
      ];

    },
    nav_drawers_kegiatan() {
      return [

      ];

    },


  },
  methods: {

    handleClick(action) {
      if (action !== "") {
        this[action]();
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.currentPeserta = ""
      this.$router.push("/login");
    },
    lookupImageUrl(item) {
      // console.log("========")
      // console.log(JSON.stringify(item))

      if (item.avatarImage === undefined || item.avatarImage === "") {
        return require("@/assets/images/account_icon.png");
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      // return require("@/assets/images/account_icon.png");
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return require("@/assets/images/account_icon.png");
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },

  },
};
</script>



<style scoped>
.title h1 {
  font-size: 0px;
  font-weight: bolder;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #3f51b5, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 24px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.waviy {
  position: relative;
  font-weight: bolder;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: #0f2270;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.custom-btn {
  color: #000000; /* Warna teks */
  background-color: #ffffff; /* Warna latar belakang */
  border-radius: 1px; /* Sudut tombol */
  letter-spacing: 0px;
}

.custom-btn:hover {
  background-color: rgb(
    235,
    243,
    235
  ); /* Warna latar belakang tipis saat hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Bayangan saat dihover */
}

.btn-switch-on {
  background-color: rgb(
    214,
    235,
    214
  ); /* Warna latar belakang tipis saat hover */
  border-radius: 5px;
}

</style>