<template>
  <nav>
    <v-toolbar :flat="currentUser === null" elevation="0">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="grey--text"
        v-if="currentUser"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-bold">E</span>
        <span>JAKONKU</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-menu offset-y v-if="currentUser">
          <template v-slot:activator="{ on, attrs }">
            <v-btn plain v-bind="attrs" v-on="on">
              <span>{{ currentUser.username }} </span>
              <v-avatar color="grey lighten-1" size="36">
                <v-img
                  :lazy-src="lookupImageUrlLazy(currentUser.avatarImage)"
                  :src="lookupImageUrl(currentUser)"
                ></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list color="grey lighten-4">
            <v-list-item
              v-for="link in top_menus"
              :key="link.title"
              route
              :to="link.route"
              @click="handleClick(link.action)"
            >
              <v-list-item-icon>
                <v-icon small :color="link.iconColor">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ link.title }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>

      <v-toolbar-items class="mx-0" v-if="false">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              rounded
              icon
              class="px-5"
              x-small
            >
              <v-icon color="black">mdi-translate</v-icon>
              <v-avatar size="18" class="elevation-1">
                <v-img :src="getFlagImage(lang)"></v-img>
              </v-avatar>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in menu_language"
              :key="index"
              dense
            >
              <v-list-item-title>
                <v-avatar size="22" class="elevation-1">
                  <v-img :src="getFlagImage(item.id)"></v-img>
                </v-avatar>
                <v-btn
                  small
                  plain
                  elevation="0"
                  @click="languageChange(item.id)"
                >
                  {{ item.description }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer
      app
      class="bottom-gradient"
      v-model="drawer"
      v-if="showNavbar"
    >
      <v-layout column class="align-center">
        <v-flex class="mt-5">
          <v-img
            :lazy-src="require('../assets/logo.png')"
            max-height="155"
            max-width="110"
            :src="require('../assets/logo.png')"
          ></v-img>
        </v-flex>
        <v-flex class="mt-4 mb-3"> </v-flex>
      </v-layout>

      <v-divider></v-divider>

      <v-list>
        <v-list-item route to="/dashboard-utama">
          <v-list-item-icon>
            <v-icon>mdi-rocket</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle>Dashboard</v-list-item-subtitle>
        </v-list-item>

        <v-list-group
          v-for="nav_drawer in nav_drawers.filter((x) => x.visible)"
          :key="nav_drawer.title"
          :prepend-icon="nav_drawer.icon"
          no-action
          small
          v-model="nav_drawer.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="nav_drawer.title">
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="submenu in nav_drawer.items.filter((x) => x.visible)"
            :key="submenu.title"
            link
            router
            :to="submenu.route"
            class="green lighten-5"
          >
            <template>
              <v-list-item-content>
                <v-list-item-title class="body-2" v-text="submenu.title">
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-icon>
              <v-icon small>{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>
        <v-list-group
            v-for="nav_drawer in nav_drawers_cbt.filter((x) => x.visible)"
            :key="nav_drawer.title"
            :prepend-icon="nav_drawer.icon"
            no-action
            small
            v-model="nav_drawer.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="nav_drawer.title">
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="submenu in nav_drawer.items.filter((x) => x.visible)"
              :key="submenu.title"
              link
              router
              :to="submenu.route"
              class="green lighten-5"
          >
            <template>
              <v-list-item-content>
                <v-list-item-title class="body-2" v-text="submenu.title">
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-icon>
              <v-icon small>{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>
        <v-list-group
            v-for="nav_drawer in nav_drawers_setup.filter((x) => x.visible)"
            :key="nav_drawer.title"
            :prepend-icon="nav_drawer.icon"
            no-action
            small
            v-model="nav_drawer.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="nav_drawer.title">
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="submenu in nav_drawer.items.filter((x) => x.visible)"
              :key="submenu.title"
              link
              router
              :to="submenu.route"
              class="green lighten-5"
          >
            <template>
              <v-list-item-content>
                <v-list-item-title class="body-2" v-text="submenu.title">
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-icon>
              <v-icon small>{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>
        <v-list-item to="#" @click="logOut">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Keluar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="my-16">
      </div>

    </v-navigation-drawer>
  </nav>
</template>

<script>
import ERole from "../models/e-role";
import FileService from "../services/apiservices/file-service";
import TestService from "../services/test-service";

export default {
  components: {},
  data() {
    return {
      menu_language: [
        { id: "en", description: "English", icon: "flag_en.jpg" },
        { id: "id", description: "Indonesia", icon: "flag_id.jpg" },
      ],

      top_menus: [
        {
          icon: "mdi-pencil",
          title: "Ubah Profile",
          route: "/change-profile",
          action: "",
          visible: true,
          iconColor: "orange",
        },
        {
          icon: "mdi-power",
          title: "Keluar",
          route: "#",
          action: "logOut",
          visible: true,
          iconColor: "red",
        },
      ],
      drawer: true,
    };
  },
  watch: {},
  computed: {
    currentUser() {
      // console.log( `Isinya ${this.$store.state.auth.user}` )
      return this.$store.state.auth.user;
    },
    showNavbar() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles;
      }
      return false;
    },

    nav_drawers() {
      return [
        {
          title: "Sertifikasi Webinar",
          icon: "mdi-star",
          active: false,
          visible: [
            ERole.ROLE_ADMIN
          ].some((x) => this.currentUser.roles.includes(x)),
          items: [
            {
              title: "Sertifikasi Webinar",
              route: "/admin-meeting",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "*PARTICIPANT",
              route: "/admin-reg-webinar",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Nara Sumber",
              route: "/admin-narsum",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Invitee Candidate",
              route: "/admin-invitee-candidate",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },

            {
              title: "Setup Form & Doc",
              route: "/admin-jenis-doc",
              icon: "mdi-file-document-outline",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },


          ],
        },

        {
          title: "E-Library",
          icon: "mdi-book-open-variant",
          active: false,
          visible: [ERole.ROLE_ADMIN].some((x) =>this.currentUser.roles.includes(x)),
          items: [
            {
              title: "Buku, Jurnal, Artikel",
              route: "/admin-article",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Kategori Artikel",
              route: "/admin-article-categ",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },

          ],
        },

        // {
        //   title: "Jasa Konstruksi",
        //   icon: "mdi-robot-industrial",
        //   active: false,
        //   visible: true,
        //   items: [
        //     {
        //       title: "Progress Kegiatan",
        //       route: "/admin-target-kegiatan",
        //       icon: "",
        //       visible: [ERole.ROLE_ADMIN].some((x) =>
        //           this.currentUser.roles.includes(x)
        //       ),
        //     },
        //     {
        //       title: "Kegiatan Bidang",
        //       route: "/admin-kegiatan-bidang",
        //       icon: "",
        //       visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN_1].some((x) =>
        //           this.currentUser.roles.includes(x)
        //       ),
        //     },
        //     {
        //       title: "Tugas dan Fungsi",
        //       route: "/admin-tugas-fungsi",
        //       icon: "",
        //       visible: [ERole.ROLE_ADMIN].some((x) =>
        //           this.currentUser.roles.includes(x)
        //       ),
        //     },
        //     {
        //       title: "Struktur Bidang",
        //       route: "/admin-struktur-bidang",
        //       icon: "",
        //       visible: [ERole.ROLE_ADMIN].some((x) =>
        //           this.currentUser.roles.includes(x)
        //       ),
        //     },
        //
        //     {
        //       title: "SDM (Pegawai)",
        //       route: "/admin-pegawai",
        //       icon: "",
        //       visible: [ERole.ROLE_ADMIN].some((x) =>
        //           this.currentUser.roles.includes(x)
        //       ),
        //     },
        //
        //   ],
        // },

        // {
        //   title: "Gallery & Berita",
        //   icon: "mdi-folder-multiple-image",
        //   active: false,
        //   visible: [ERole.ROLE_ADMIN].some((x) =>this.currentUser.roles.includes(x)),
        //   items: [
        //     {
        //       title: "Gallery",
        //       route: "/admin-gallery",
        //       icon: "",
        //       visible: true,
        //     },
        //     {
        //       title: "Berita Agenda",
        //       route: "/admin-berita-agenda",
        //       icon: "",
        //       visible: true,
        //     },
        //   ],
        // },

        // {
        //   title: "Kegiatan",
        //   icon: "mdi-calendar",
        //   active: false,
        //   visible: [ERole.ROLE_ADMIN].some((x) =>this.currentUser.roles.includes(x)),
        //   items: [
        //     { title: "Sertif & Pelatihan", route: "/admin-sertif-pelatihan", icon: "mdi-seal", visible: true },
        //
        //     { title: "Peng. Tertib Usaha", route: "/admin-pengawasan-tertib-usaha", icon: "mdi-store", visible: true },
        //     { title: "Peng. Tertib Jasa", route: "/admin-pengawasan-tertib-jasa", icon: "mdi-gift", visible: true },
        //     { title: "Peng. Tertib Produk", route: "/admin-pengawasan-tertib-produk", icon: "mdi-qrcode-scan", visible: true },
        //     { title: "Tenaga Bersertifikat", route: "/admin-tenaga-bersertifikat", icon: "mdi-account-star", visible: true },
        //
        //   ],
        // },

        {
          title: "Laporan",
          icon: "mdi-chart-pie",
          active: false,
          visible: false,
          items: [
            { title: "Laporan", route: "/reports", icon: "", visible: true },
            {
              title: "Data Analisis",
              route: "/data-analisis",
              icon: "",
              visible: true,
            },
          ],
        },


      ];
    },

    nav_drawers_cbt() {
      return [
        {
          title: "Data Modul",
          icon: "mdi-clipboard-account",
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
              this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Topik",
              route: "/admin-cbt-topik",
              icon: "",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Soal",
              route: "/admin-cbt-tes-soal-bak",
              icon: "mdi-pen",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
          ],
        },
        {
          title: "Exam/Quiz",
          icon: "mdi-cog",
          active: false,
          visible: [ERole.ROLE_ADMIN].some((x) =>
              this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Tes, Quiz & Try-Out",
              route: "/admin-cbt-tes",
              icon: "",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Papan Skor Nilai",
              route: "/admin-cbt-hasil-tes-soal-choice",
              icon: "",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
          ],
        },

      ];
    },


    nav_drawers_setup() {
      return [

        {
          title: "Setup",
          icon: "mdi-cog",
          active: false,
          visible: [ERole.ROLE_ADMIN].some((x) =>
              this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Wilayah Adm",
              route: "/admin-wilayah",
              icon: "mdi-map",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },

            {
              title: "Gambar Slide",
              route: "/admin-din-carousel",
              icon: "mdi-image",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
          ],
        },
        {
          title: "Admin",
          icon: "mdi-cog-outline",
          active: false,
          visible: [ERole.ROLE_ADMIN].some((x) =>
              this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Pengguna",
              route: "/admin-sistem",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Struktur Organisasi",
              route: "/admin-struktur-organisasi",
              icon: "mdi-file-tree",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Setting Aplikasi",
              route: "/admin-setting-one",
              icon: "mdi-cogs",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
          ],
        },
      ];
    },

    lang: {
      get() {
        return this.$store.state.langModule.lang;
      },
      set(val) {
        this.$store.dispatch("langModule/setLang", val);
      },
    },
  },

  methods: {
    getFlagImage(fileName) {
      const lang = fileName;
      if (lang === undefined) {
        return require("@/assets/i18n/flag_id.jpg");
      } else {
        const langJson = this.menu_language.filter(
          (item) => item.id === `${lang}`
        );
        return require("@/assets/i18n/" + langJson[0].icon);
      }
    },

    languageChange(value) {
      this.lang = value;
      this.$i18n.locale = this.lang;
    },
    handleClick(action) {
      if (action !== "") {
        this[action]();
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },


    lookupImageUrl(item) {
      // console.log(JSON.stringify(item))
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return require("@/assets/images/account_icon.png");
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
    lookupImageUrlLazy() {
      return require("@/assets/images/account_icon.png");
    },
  },
  mounted() {
    TestService.getAdminBoard().then(
      () => {},
      (error) => {

        if (error.response.status === 401) {
          if (this.currentUser !== undefined || this.currentUser !== "") {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        }
      }
    );
  },
};
</script>

<style scoped>
</style>