import FtRegistration from "@/models/ft-registration";

export const UploadWebinarModule = {
    namespaced: true,
    state: {
        itemModified: new FtRegistration(),
        itemDefault: Object,
        itemsFtRegistrationFile: [],


    },
    actions: {
        updateItemModified({ commit }, payload) {
            commit("mutateItemModified", payload);
        },
        updateItemDefault({ commit }, payload) {
            commit("mutateItemDefault", payload);
        },
        updateItemsFtRegistrationFile({ commit }, payload) {
            commit("mutateItemsFtRegistrationFile", payload);
        },

    },

    mutations: {
        mutateItemModified(state, payLoad) {
            state.itemModified = payLoad;
        },
        mutateItemDefault(state, payLoad) {
            state.itemDefault = payLoad;
        },
        mutateItemsFtRegistrationFile(state, payLoad) {
            state.itemsFtRegistrationFile = payLoad;
        },
    },
};
